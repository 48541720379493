// vant 国际化
import moment from 'moment';

function changeLang(newlang) {
  switch (newlang) {
    case 'zh_CN':
      moment.locale('zh-cn');
      break;
    case 'in_ID':
      moment.locale('id');
      break;
    default:
      moment.locale('en');
      break;
  }
}
 
export function Local(mylang) {
  changeLang(mylang)
}