import { Dialog } from 'vant';

const confirm = param => {
  const { message, ...rest } = param;
  return Dialog.confirm({
    message: '<div><img class="my-dialog-icon" src="/images/notify-error.png" /><p class="my-dialog-text">'+ message +'</p></div>',
    allowHtml: true,
    theme: 'round-button',
    ...rest,
    title: undefined,
  });
};

const alert = param => {
  const { message, ...rest } = param;
  return Dialog.alert({
    message: '<div><img class="my-dialog-icon" src="/images/notify-error.png" /><p class="my-dialog-text">'+ message +'</p></div>',
    allowHtml: true,
    theme: 'round-button',
    ...rest,
    title: undefined,
  });
};


export default { confirm, alert };