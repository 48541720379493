import axios from "@/api/axios";

//上传图片
export const uploadImg = formData => axios({ 
  url: "/farmer-api/uploadImg",
  method: 'upload',
  formData,
});

//首页banner 公告
export const queryAppInfo = data => axios({ url: "/farmer-api/config/info/v2",  data });
export const queryBannerList = data => axios({ url: "/farmer-api/banner/list",  data });
export const queryCommissionCarousel = data => axios({ url: "/farmer-api/commissionCarousel",  data });
export const queryConfigFunction = data => axios({ url: "/farmer-api/config/function",  data });
export const queryBannerShowList = data => axios({ url: "/farmer-api/banner/show/list",  data });

//交易app地址
export const queryTransactionUrl = data => axios({ url: "/farmer-api/transaction/url",  data });


// 登录注册
export const getAreaCode = data => axios({ url: "/farmer-api/area/code",  data });
export const sendLoginVerifyCode = data => axios({ url: "/farmer-api/sms/send/login",  data });

export const phoneLogin = data => axios({ 
  url: "/farmer-api/user/login/v2",
  method: 'post',
  data,
});
export const pwdLogin = data => axios({ 
  url: "/farmer-api/user/login/pwd/v2",
  method: 'post',
  data,
});
export const sendRegisterVerifyCode = data => axios({ url: "/farmer-api/sms/send/register",  data });
export const register = data => axios({ 
  url: "/farmer-api/user/register/v2",
  method: 'post',
  data,
});
export const sendForgetVerifyCode = data => axios({ url: "/farmer-api/sms/send/forgetLoginPassword",  data });
export const forgetLoginPassword = data => axios({ 
  url: "/farmer-api/loginPassword/forget",
  method: 'post',
  data,
});
export const modifyLoginPassword = data => axios({ 
  url: "/farmer-api/loginPassword/modify",
  method: 'post',
  data,
});

export const loginOut = data => axios({ 
  url: "/farmer-api/user/login/out",
  method: 'post',
  data,
});

//虚币法币 充值 提现
export const getCoinWithdrawInfo = data => axios({ url: "/farmer-api/coin/withdraw/init",  data });
export const createCoinWithdraw = data => axios({ 
  url: "/farmer-api/coin/withdraw/advance",
  method: 'post',
  data,
});
export const confirmCoinWithdraw = data => axios({ 
  url: "/farmer-api/coin/withdraw/submit",
  method: 'post',
  data,
});
export const queryCoinWithdrawHanding = data => axios({ url: "/farmer-api/coin/withdraw/handingWithdraw",  data });
export const queryCoinWithdrawHistory = data => axios({ url: "/farmer-api/coin/withdraw/historyWithdraw",  data });

export const queryAddressList = data => axios({ url: "/farmer-api/userAddress", data});

export const createCoinRecharge = data => axios({ 
  url: "/farmer-api/coin/recharge",
  method: 'post',
  data,
});
export const getCoinRechargeChannel = data => axios({ 
  url: "/farmer-api/coin/channel",
  method: 'post',
  data,
});
export const payCoinRecharge = data => axios({ 
  url: "/farmer-api/coin/pay",
  method: 'post',
  data,
});
export const queryCoinRechargeHanding = data => axios({ url: "/farmer-api/coin/handingRecharge",  data });
export const queryCoinRechargeHistory = data => axios({ url: "/farmer-api/coin/historyRecharge",  data });


export const initLegalRecharge = data => axios({ 
  url: "/farmer-api/legal/recharge/init",
  method: 'post',
  data,
});
export const createLegalRecharge = data => axios({ 
  url: "/farmer-api/legal/recharge",
  method: 'post',
  data,
});
export const confirmLegalRecharge = data => axios({ 
  url: "/farmer-api/legal/recharged",
  method: 'post',
  data,
});
export const queryLegalRechargeHanding = data => axios({ url: "/farmer-api/legal/handingRecharge",  data });
export const queryLegalRechargeHistory = data => axios({ url: "/farmer-api/legal/historyRecharge",  data });

export const queryLegalRechargeChannel = data => axios({ url: "/farmer-api/legal/channel",  data });
export const initLegalChannelRecharge = data => axios({ 
  url: "/farmer-api/legal/channel/init",
  method: 'post',
  data,
});
export const createLegalChannelRecharge = data => axios({ 
  url: "/farmer-api/legal/channel/recharge",
  method: 'post',
  data,
});

export const createLegalWithdraw = data => axios({ 
  url: "/farmer-api/legal/withdraw/init",
  method: 'post',
  data,
});
export const confirmLegalWithdraw = data => axios({ 
  url: "/farmer-api/legal/withdraw/handle",
  method: 'post',
  data,
});
export const queryLegalWithdrawHanding = data => axios({ url: "/farmer-api/legal/withdraw/handing",  data });
export const queryLegalWithdrawHistory = data => axios({ url: "/farmer-api/legal/withdraw/history",  data });

//急速买币
export const queryPaymentList = data => axios({ url: "/farmer-api/payment/list",  data });

export const takingPayment = data => axios({ 
  url: "/farmer-api/payment/taking",
  method: 'post',
  data,
});

export const queryPaymentProcessing = data => axios({ url: "/farmer-api/payment/processing/list",  data });
export const queryPaymentHistory = data => axios({ url: "/farmer-api/payment/history/list",  data });

export const finishPaymentOrder = data => axios({ 
  url: "/farmer-api/payment/order/finish",
  method: 'post',
  data,
});
export const cancelPaymentOrder = data => axios({ 
  url: "/farmer-api/payment/order/fail",
  method: 'post',
  data,
});
export const reUploadPaymentOrder = data => axios({ 
  url: "/farmer-api/payment/reUpload/finish",
  method: 'post',
  data,
});


//保证金
export const addEarnest = data => axios({ 
  url: "/farmer-api/account/earnest",
  method: 'post',
  data,
});
export const refundEarnest = data => axios({ 
  url: "/farmer-api/account/earnest/refund",
  method: 'post',
  data,
});
export const queryMinEarnest = data => axios({ url: "/farmer-api/account/earnest/min",  data });

//银行卡
export const queryRechargeBankcardList = data => axios({ url: "/farmer-api/bankcard/recharge",  data });
export const queryWithdrawalBankcardList = data => axios({ url: "/farmer-api/bankcard/withdraw",  data });

export const queryBankList = data => axios({ url: "/farmer-api/bankList",  data }); // 获取银行列表

export const addRechargeBankcard = data => axios({ 
  url: "/farmer-api/bankcard/recharge",
  method: 'post',
  data,
});
export const deleteRechargeBankcard = data => axios({ 
  url: `/farmer-api/bankcard/recharge/${data.id}`,
  method: 'DELETE',
});
export const addWithdrawalBankcard = data => axios({ 
  url: "/farmer-api/bankcard/withdraw",
  method: 'post',
  data,
});
export const sendWithdrawalBankcardSms = data => axios({ url: "/farmer-api/sms/send/bankcard/withdraw",  data });

//收款地址
export const querySellerAccountInfo = data => axios({ url: "/farmer-api/sellerAccount/info",  data });
export const querySellerAccountList = data => axios({ url: "/farmer-api/sellerAccount/list",  data });
export const addSellerAccount = data => axios({ 
  url: "/farmer-api/sellerAccount/add",
  method: 'post',
  data,
});
export const modifySellerAccount = data => axios({ 
  url: "/farmer-api/sellerAccount/modify",
  method: 'post',
  data,
});
export const delSellerAccount = data => axios({ 
  url: "/farmer-api/sellerAccount/del",
  method: 'post',
  data,
});
export const enableSellerAccount = data => axios({ 
  url: "/farmer-api/sellerAccount/enable",
  method: 'post',
  data,
});
export const disableSellerAccount = data => axios({ 
  url: "/farmer-api/sellerAccount/disable",
  method: 'post',
  data,
});
export const enableAutoTaking = data => axios({ 
  url: `/farmer-api/autoTaking/${data.type}/enable`,
  method: 'post',
});
export const disableAutoTaking = data => axios({ 
  url: `/farmer-api/autoTaking/${data.type}/disable`,
  method: 'post',
});



//用户地址
export const queryUserAccountList = data => axios({ url: "/farmer-api/userAddress",  data });
export const addUserAccount = data => axios({
  url: "/farmer-api/userAddress/add",
  method: 'post',
  data,
});
export const updateUserAccount = data => axios({
  url: "/farmer-api/userAddress/update",
  method: 'post',
  data,
});
// export const delWalletAccount = data => axios({
//   url: "/farmer-api/userAddress/del",
//   method: 'post',
//   data,
// });
export const sendAddUserAccountSms = data => axios({
  url: "/farmer-api/userAddress/sendCode/add",
  method: 'post',
  data,
});
export const sendUpdateUserAccountSms = data => axios({
  url: "/farmer-api/userAddress/sendCode/update",
  method: 'post',
  data,
});

//账户信息
export const queryAccountInfo = (data, config) => axios({ url: "/farmer-api/account/detail",  data, config });
export const queryAccountMarkingRate = data => axios({ url: "/farmer-api/account/marking/rate",  data });
export const queryRechargePage = data => axios({ url: "/farmer-api/config/recharge",  data });
export const queryOnlineServicePage = data => axios({ url: "/farmer-api/config/customerService",  data });

export const queryWithdrawPage = data => axios({ url: "/farmer-api/config/withdraw",  data });
export const queryContibutionPage = data => axios({ url: "/farmer-api/config/contribution",  data });

//市场 订单
export const queryOrderTakingList = data => axios({ url: "/farmer-api/order/taking/list",  data });
export const takingOrder = data => axios({ 
  url: "/farmer-api/order/taking",
  method: 'post',
  data,
});
export const doneOrder = data => axios({ 
  url: "/farmer-api/order/done",
  method: 'post',
  data,
});

export const failToDone = data => axios({
  url: "/farmer-api/order/failToDone",
  method: 'post',
  data,
});


export const failOrder = data => axios({ 
  url: "/farmer-api/order/fail",
  method: 'post',
  data,
});
export const queryOrderProcessingList = data => axios({ url: "/farmer-api/order/processing/list",  data });
export const queryOrderTimeoutList = data => axios({ url: "/farmer-api/order/timeout/list",  data });
export const queryOrderDoneList = data => axios({ url: "/farmer-api/order/done/list",  data });
export const queryOrderFailList = data => axios({ url: "/farmer-api/order/fail/list",  data });
export const queryOrderReminderList = data => axios({ url: "/farmer-api/order/reminding/list",  data });
export const queryOrderTimeout = data => axios({ url: "/farmer-api/order/timeout/age",  data });
// export const remindingOrder = data => axios({ 
//   url: "/farmer-api/order/upload/reminding/img",
//   method: 'post',
//   data,
// });


//流水
export const queryJournalList = data => axios({ url: "/farmer-api/user/journal",  data });

//历史
export const querySubtotalHistoryList = data => axios({ url: "/farmer-api/subtotal/history",  data });
// export const querySubtotalHistoryList = data => axios({ 
//   url: '/farmer-api/subtotal/history',
//   method: 'post',
//   data,
// });

//接单卡
export const queryCCardList = data => axios({ url: "/farmer-api/contributionCard",  data });
export const buyContributionCard = data => axios({ 
  url: `/farmer-api/contributionCard/buy/${data.id}`,
  method: 'post',
});
export const queryCCardPurchaseList = data => axios({ url: "/farmer-api/contributionCard/purchase",  data });
export const queryCCardActiveList = data => axios({ url: "/farmer-api/contributionCard/purchase/using",  data });
export const queryCCardUseRecordList = data => axios({ url: "/farmer-api/contributionCard/issue",  data });
export const queryContributionJournal = data => axios({ url: "/farmer-api/user/contribution/journal",  data });
export const queryCCardCategory = data => axios({ url: "/farmer-api/collecting/show",  data });
export const queryCCardInCategory = data => axios({ url: `/farmer-api/collecting/show/card/${data.id}`, data });

//下级
export const getSubordinateList = data => axios({ url: "/farmer-api/subtotal/lower/user",  data });

//分享
export const getShareInfo = data => axios({ url: "/farmer-api/share/info",  data });
export const getShareUrl = data => axios({ url: "/farmer-api/share/url",  data });

//任务
export const queryMissionList = data => axios({ url: "/farmer-api/mission/list",  data });
export const receiveMission = data => axios({ 
  url: '/farmer-api/mission/receive',
  method: 'post',
  data,
});

//宝箱任务
export const queryPromotionList = data => axios({ url: "/farmer-api/mission/treasure/list",  data });
export const receivePromotion = data => axios({ 
  url: '/farmer-api/mission/receive',
  method: 'post',
  data,
});


//提示
export const queryPageTip = data => axios({ url: "/farmer-api/config/tip",  data });

//快捷菜单
export const queryQuickMenu = (data, config) => axios({ url: "/farmer-api/config/suspension/window",  data, config });

//语言
export const configLocal = data => axios({ 
  url: '/farmer-api/config/local',
  method: 'post',
  data,
});

//客服
export const queryOnlineService = data => axios({ url: "/farmer-api/customer/service/list",  data });

//游戏列表
export const queryGameList = data => axios({ url: "/farmer-api/game/list",  data });
//真人游戏列表
export const queryLiveGameBanner = data => axios({ url: "/farmer-api/game/live/menu/banner",  data });
export const queryLiveGameCategory = data => axios({ url: "/farmer-api/game/live/menu/category",  data });
export const queryLiveGameList = data => axios({ url: "/farmer-api/game/live/menu",  data });
export const enterLiveGame = data => axios({ url: "/farmer-api/game/live/enter",  data });


//luck hash游戏
export const queryGame = (data, config) => axios({ url: "/farmer-api/game/current",  data, config });
export const queryGameAccountBalance = (data, config) => axios({ url: "/farmer-api/game/balance",  data, config });
export const gameBetting = (data, config) => axios({ 
  url: '/farmer-api/game/betting',
  method: 'post',
  config,
  data,
});
export const gameOrderCancel = data => axios({
  url: '/farmer-api/game/order/cancel',
  method: 'post',
  data,
});
export const gameOrderBatchCancel = data => axios({ 
  url: '/farmer-api/game/order/batchCancel',
  method: 'post',
  data,
});
//用户下注记录
export const queryMyBetRecord = (data, config) => axios({ url: "/farmer-api/game/order/lotteryRecord",  data, config });

//往期开奖结果
export const queryLottery = data => axios({ url: "/farmer-api/game/lottery",  data });

//用户游戏订单
export const queryGameBill = data => axios({ url: "/farmer-api/game/orders",  data });

//当期获奖结果
export const queryMyLotteryResult = (data, config) => axios({ url: "/farmer-api/game/userLotteryResult",  data, config });

//用户获奖结果列表
export const queryMyLotteryResultList = data => axios({ url: "/farmer-api/game/userLottery",  data });

//自定义筹码
export const queryCustomChip = data => axios({ url: "/farmer-api/game/custom/query",  data });
export const modifyCustomChip = data => axios({ 
  url: '/farmer-api/game/custom/add',
  method: 'post',
  data,
});

//足球游戏
export const queryLeague = data => axios({ url: "/farmer-api/ball/league",  data });
export const queryMatch = data => axios({ url: "/farmer-api/ball/list",  data });
export const queryMatchBets = data => axios({ url: "/farmer-api/ball/oddsBets",  data });
export const queryMatchBetAmount = data => axios({ url: "/farmer-api/ball/order/bets",  data });
export const queryBallOrder = data => axios({ url: "/farmer-api/ball/order",  data });
export const initBallBetting = data => axios({ 
  url: "/farmer-api/ball/betting/init",
  method: 'post',
  data,
});
export const confirmBallBetting = data => axios({ 
  url: "/farmer-api/ball/betting",
  method: 'post',
  data,
});

//授权
export const queryAuthorizationInfo = data => axios({ url: "/farmer-api/authorization/info",  data });
export const queryAuthorizationManage = data => axios({ url: "/farmer-api/authorization/manage",  data });
export const authorizationAgree = data => axios({ 
  url: '/farmer-api/authorization/agree',
  method: 'post',
  data,
});
export const authorizationRevocation = data => axios({ 
  url: '/farmer-api/authorization/revocation',
  method: 'post',
  data,
});

//等级
export const queryRankInfo = data => axios({ url: "/farmer-api/rank/info",  data });
