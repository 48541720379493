import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/user/login'),
    meta: {
      title: 'Login',
    },
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('./view/user/register'),
    meta: {
      title: 'Register',
    },
  },
  {
    name: 'forgetPassword',
    path: '/password/forget',
    component: () => import('./view/user/forgetPassword'),
    meta: {
      title: 'Forget Password',
    },
  },
  {
    name: 'modifyPassword',
    path: '/password/modify',
    component: () => import('./view/user/modifyPassword'),
    meta: {
      title: 'Modify Password',
    },
  },
  {
    name: 'tab',
    path: '/tab',
    component: () => import('./layout/TabbarLayout'),
    meta: {
      title: 'Miner',
    },
    children:[{
      name: 'home',
      path: '/home',
      component: () => import('./view/home'),
    },{
      name: 'workbench',
      path: '/workbench',
      component: () => import('./view/workbench'),
    },{
      name: 'show',
      path: '/show',
      component: () => import('./view/show'),
    },{
      name: 'mine',
      path: '/mine',
      component: () => import('./view/mine'),
    }]
  },
  {
    name: 'article',
    path: '/article',
    component: () => import('./view/home/article'),
    meta: {
      title: 'Article',
    },
  },
  {
    name: 'coinRecharge',
    path: '/coin/recharge',
    component: () => import('./view/coin/coinRecharge'),
    meta: {
      title: 'Coin Recharge',
    },
  },
  {
    name: 'coinRechargeChannel',
    path: '/coin/recharge/channel',
    component: () => import('./view/coin/coinRechargeChannel'),
    meta: {
      title: 'Coin Recharge Channel',
    },
  },
  {
    name: 'coinRechargeHistory',
    path: '/coin/recharge/history',
    component: () => import('./view/coin/coinRechargeHistory'),
    meta: {
      title: 'Coin Recharge',
    },
  },
  {
    name: 'coinWithdraw',
    path: '/coin/withdraw',
    component: () => import('./view/coin/coinWithdraw'),
    meta: {
      title: 'Coin Withdraw',
    },
  },
  {
    name: 'coinRechargeChannel',
    path: '/coin/recharge/channel',
    component: () => import('./view/coin/coinRechargeChannel'),
    meta: {
      title: 'Coin Recharge Channel',
    },
  },
  {
    name: 'coinWithdrawHistory',
    path: '/coin/withdraw/history',
    component: () => import('./view/coin/coinWithdrawHistory'),
    meta: {
      title: 'Coin Withdraw',
    },
  },
  {
    name: 'coinWithdrawSuccess',
    path: '/coin/withdraw/success/:type',
    component: () => import('./view/coin/coinWithdrawSuccess'),
    meta: {
      title: 'Coin Withdraw Success',
    },
  },
  {
    name: 'legalRecharge',
    path: '/legal/recharge',
    component: () => import('./view/coin/legalRecharge'),
    meta: {
      title: 'Legal Recharge',
    },
  },
  {
    name: 'legalRechargeDetail',
    path: '/legal/recharge/detail',
    component: () => import('./view/coin/legalRechargeDetail'),
    meta: {
      title: 'Legal Recharge',
    },
  },
  {
    name: 'legalRechargeHistory',
    path: '/legal/recharge/history',
    component: () => import('./view/coin/legalRechargeHistory'),
    meta: {
      title: 'Legal Recharge',
    },
  },
  {
    name: 'legalChannelRecharge',
    path: '/legal/channelrecharge',
    component: () => import('./view/coin/legalChannelRecharge'),
    meta: {
      title: 'Legal Recharge',
    },
  },
  {
    name: 'legalWithdraw',
    path: '/legal/withdraw',
    component: () => import('./view/coin/legalWithdraw'),
    meta: {
      title: 'Legal Withdraw',
    },
  },
  {
    name: 'legalWithdrawHistory',
    path: '/legal/withdraw/history',
    component: () => import('./view/coin/legalWithdrawHistory'),
    meta: {
      title: 'Legal Withdraw',
    },
  },
  {
    name: 'bankRecharge',
    path: '/bankRecharge',
    component: () => import('./view/mine/bankRecharge'),
    meta: {
      title: 'Bank',
    },
  },
  {
    name: 'bankWithdrawal',
    path: '/bankWithdrawal',
    component: () => import('./view/mine/bankWithdrawal'),
    meta: {
      title: 'Bank',
    },
  },
  {
    name: 'editAccount',
    path: '/bank/editAccount/:type',
    component: () => import('./view/mine/BankAccountForm'),
    meta: {
      title: 'Bank Form',
    },
  },
  {
    name: 'receiveAddress',
    path: '/mine/receiveaddress',
    component: () => import('./view/mine/receiveAddress'),
    meta: {
      title: 'Receive Address',
    },
  },
  {
    name: 'sellerAccount',
    path: '/mine/selleraccount',
    component: () => import('./view/mine/sellerAccount'),
    meta: {
      title: 'Seller Account',
    },
  },
  {
    name: 'addSellerAccount',
    path: '/mine/addSellerAccount',
    component: () => import('./view/mine/SellerAccountForm'),
    meta: {
      title: 'Seller Account',
    },
  },
  {
    name: 'sellerAccountDetail',
    path: '/mine/sellerAccountDetail',
    component: () => import('./view/mine/sellerAccountDetail'),
    meta: {
      title: 'Seller Account',
    },
  },
  {
    name: 'qrcode',
    path: '/qrcode',
    component: () => import('./view/qrcode'),
    meta: {
      title: 'Qrcode',
    },
  },
  {
    name: 'market',
    path: '/market',
    component: () => import('./view/order/market'),
    meta: {
      title: 'Market',
    },
  },
  {
    name: 'orderDetail',
    path: '/order/detail',
    component: () => import('./view/order/detail'),
    meta: {
      title: 'Detail',
    },
  },
  {
    name: 'orderInProcess',
    path: '/order/inprocess',
    component: () => import('./view/order/inprocess'),
    meta: {
      title: 'Order In Process',
    },
  },
  {
    name: 'orderCompleted',
    path: '/order/completed',
    component: () => import('./view/order/completed'),
    meta: {
      title: 'Order Completed',
    },
  },
  {
    name: 'orderReminder',
    path: '/order/reminder',
    component: () => import('./view/order/reminder'),
    meta: {
      title: 'Order Reminder',
    },
  },
  {
    name: 'contributionCard',
    path: '/contribution/card',
    component: () => import('./view/contribution/card'),
    meta: {
      title: 'Contribution Card',
    },
  },
  {
    name: 'contributionCardList',
    path: '/contribution/category/:categoryId/card',
    component: () => import('./view/contribution/cardList'),
    meta: {
      title: 'Contribution Card Detail',
    },
  },
  {
    name: 'contributionCardDetail',
    path: '/contribution/card/detail',
    component: () => import('./view/contribution/cardDetail'),
    meta: {
      title: 'Contribution Card Detail',
    },
  },
  {
    name: 'contributionRecord',
    path: '/contribution/record',
    component: () => import('./view/contribution/record'),
    meta: {
      title: 'Contribution Record',
    },
  },
  {
    name: 'subordinate',
    path: '/subordinate',
    component: () => import('./view/subordinate'),
    meta: {
      title: 'Subordinate',
    },
  },
  {
    name: 'language',
    path: '/language',
    component: () => import('./view/mine/language'),
    meta: {
      title: 'Language Selection',
    },
  },
  {
    name: 'history',
    path: '/history',
    component: () => import('./view/workbench/history'),
    meta: {
      title: 'History',
    },
  },
  {
    name: 'journal',
    path: '/journal',
    component: () => import('./view/workbench/journal'),
    meta: {
      title: 'Journal',
    },
  },
  {
    name: 'task',
    path: '/task',
    component: () => import('./view/task/index'),
    meta: {
      title: 'Task Center',
    },
  },
  {
    name: 'promotion',
    path: '/promotion',
    component: () => import('./view/promotion/index'),
    meta: {
      title: 'Promotion',
    },
  },
  {
    name: 'onlineService',
    path: '/onlineService',
    component: () => import('./view/mine/onlineService'),
    meta: {
      title: 'Online Service',
    },
  },
  {
    name: 'userAddress',
    path: '/userAddress',
    component: () => import('./view/mine/userAddress'),
    meta: {
      title: 'User Address',
    },
  },
  {
    name: 'addUserAccount',
    path: '/addUserAccount',
    component: () => import('./view/mine/UserAccountForm'),
    meta: {
      title: 'User Account',
    },
  },
  {
    name: 'authorization',
    path: '/authorization',
    component: () => import('./view/mine/authorization'),
    meta: {
      title: 'Authorization',
    },
  },
  {
    name: 'earnest',
    path: '/earnest',
    component: () => import('./view/earnest/index'),
    meta: {
      title: 'Security Deposit',
    },
  },
  {
    name: 'paymentBuy',
    path: '/payment/buy',
    component: () => import('./view/payment/buy'),
    meta: {
      title: 'Buy',
    },
  },
  {
    name: 'paymentDetail',
    path: '/payment/detail',
    component: () => import('./view/payment/detail'),
    meta: {
      title: 'Payment Detail',
    },
  },
  {
    name: 'paymentOrder',
    path: '/payment/order',
    component: () => import('./view/payment/order'),
    meta: {
      title: 'Payment Order',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if (!localStorage.getItem('userInfo') && to.name !== 'login' && to.name !== 'register') {
    next({ name: 'login' });
  }
  next();
});

export { router };
