// store.js
import { reactive } from 'vue';
import { queryQuickMenu, queryTransactionUrl } from "@/api";

export const store = reactive({
  documentVisibility: false,
  setDocumentVisibility(documentVisibility) {
    this.documentVisibility = documentVisibility
  },
  
  quickMenu: [],
  fetchQuickMenu() {
    queryQuickMenu().then(res => {
      this.quickMenu = res.data || [];
    });
  },
  
  transactionUrl: '',
  fetchTransactionUrl() {
    queryTransactionUrl().then(res => {
      this.transactionUrl = res.data || [];
    });
  },
})