const lang = {
  common: {
    add: 'Tambah',
    edit: 'Sunting',
    delete: 'Hapus',
    cancel: 'Batalkan',
    today: 'Hari ini',
    noData: 'Tidak ada Data',
    noMore: 'Habis',
    loading: 'Memuat',
    downloading: 'Mengunduh',
  },
  placeholder: {
    default: 'Silahkan Masukan',
    optional: 'Opsional',
    phone: 'Telepon',
    verifyCode: 'Kode verifikasi',
    password: 'Kata Sandi',
    inviteCode: 'Kode Undangan',
    oldPwd: 'Kata Sandi Lama',
    newPwd: 'Kata Sandi Baru',
    newPwdAgain: 'Ulangi Kata Sandi Baru',
    minPrice: 'Harga Min',
    maxPrice: 'Harga Max',
  },
  field: {
    available: 'Tersedia',
    available2: 'Tersedia',
    frozen: 'Dalam proses',
    income: 'Pendapatan',
    todayIncome: 'Pendapatan hari ini',
    buy: 'Beli',
    payAmount: 'Jumlah Bayar',
    receivedAmount: 'Jumlah Terima',
    exchangeRate: 'Nilai Penukaran',
    appraisal: 'Penilaian',
    commission: 'Biaya',
    bankCardNumber: 'Nomor Kartu Bank',
    bankName: 'Nama Bank',
    payee: 'Penerima Pembayaran',
    payer: 'Pembayar',
    remark: 'Komentar',
    cardholder: 'Pemegang Kartu',
    verifyCode: 'Kode Verifikasi',
    amount: 'Jumlah',
    address: 'Alamat',
    balance: 'Saldo',
    availableBalance: 'Saldo Tersedia',
    orderNumber: 'Nomor Pemesanan',
    orderTime: 'Waktu Pemesanan',
    contribution: 'Kontribusi',
    income2: 'Pendapatan',
    account: 'Akun',
    needDeposit: 'Butuh Setoran',
    dayContribution: 'Kontribusi Harian',
    purchaseTime: 'Waktu Pembelian',
    deposit: 'Setoran',
    depositStatus: 'Status Setoran',
    depositRefundTime: 'Waktu Pengembalian Setoran',
    status: 'Status',
    useDay: 'Masa berlaku',
    usingDay: 'Hari Terpakai',
    usingTime: 'Waktu yang terpakai',
    type: 'Tipe',
    realname: 'Nama asli',
    failMsg: 'Alasan kegagalan',
    teamTurnover: 'Tim Pengembalian',
    teamOrders: 'Tim Pemesanan',
    lastTradingTime: 'Waktu Trading Terakhir',
    invitationCodeRate: 'Tarif kode Undangan',
    startTime: 'Waktu Mulai',
    endTime: 'Waktu Selesai',
    orderCredits: 'Kartu pesanan',
    expiring: 'Akan segera kedaluarsa',
    channel: 'Saluran',
    request: 'Aplikasi',
  },
  title: {
    login: 'Masuk',
    logout: 'Logout',
    register: 'Daftar',
    historyData: 'Riwayat Data',
    pay: 'Bayar',
    rechargeDetail: 'Rincian Isi Ulang',
    success: 'Sukses',
    buyCoinBankcard: 'Tambahkan kartu bank untuk membeli koin',
    withdrawalBankcard: 'Bank Account',
    detail: 'Rincian',
    workbench: 'Statistik',
    accountDetail: 'Detail Akun',
    expirationTime: 'Waktu Kadaluarsa:[[1]]menit',
    priceFilter: 'Kisaran Harga',
    market: 'Pesanan perdagangan',
    userAddress: 'buku alamat',
  },
  tabTitle: {
    buyCoins: 'Beli koin',
    withdrawal: 'Penarikan',
    rechargeProcessing: 'Memproses Isi Ulang',
    withdrawProcessing: 'Memproses Penarikan',
    success: 'Sukses',
    failed: 'Gagal',
    haveInHand: 'Catatan Pengiriman',
    expired: 'Kadaluarsa',
    receiptFragment: 'Tersedia',
    contact: 'Milik Saya',
    cCardPurchase: 'Kartu Saya',
    cCardPurchaseUsing: 'Sedang digunakan',
    cCardPurchaseIsUsed: 'Riwayat',
    recharge: 'ISI ULANG',
  },
  button: {
    login: 'Masuk',
    register: 'Daftar',
    goToLogin: 'Kembali ke Masuk',
    goToRegister: 'Kembali Ke Pendaftaran',
    smsBtn: 'Kirim kode verifikasi',
    smsResend: 'Kirim Kembali',
    smsColdDown: 'Memuat Kembali',
    second: ' Kedua',
    pwdLogin: 'Kata Sandi Masuk',
    otpLogin: 'OTP Login',
    forgetPwd: 'Lupa Kata Sandi',
    finish: 'Selesai',
    close: 'Tutup',
    open: 'Buka',
    submit: 'Kirim',
    confirm: 'Konfirmasi',
    nextBtn: 'Selanjutnya',
    continueWithdraw: 'Lanjutkan Penarikan',
    back: 'Kembali',
    orderReceiving: 'Menerima Pesanan',
    completed: 'Lengkap',
    done: 'Selesai',
    fail: 'Gagal',
    share: 'Bagikan',
    purchase: 'Beli',
    purchased: 'Dibeli',
    soldOut: 'Habis',
    authorization: 'otorisasi',
  },
  alert: {
    default: 'Tolong diingat',
    warnning: 'Peringatan',
    rechargeSuccessful: 'Pengisian Ulang Sukses?',
    hasReceived: 'Konfirmasi[[1]]Pembayaran telah diterima[[2]][[3]]',
    notReceived: 'Pembayaran belum diterima',
    deductContribution: 'Pengurangan kontribusi',
    close: 'Tutup?',
    open: 'Buka?',
    purchase: 'Konfirmasi Pembelian?',
    deleteBankCard: 'Hapus Kartu Bank?',
    addRechargeBankCard: 'Tambahkan kartu bank isi ulang / Tolong tambahkan kartu bank untuk isi ulang',
    updateVersion: 'Konfirmasi Pembaruan?',
    logout: 'Keluar / Apa anda yakin ingin keluar dari akun ini?',
    betOrderCancel: 'Are you sure to cancel your bet?',
    betOrderBatchCancel: 'Are you sure to cancel all bets?',
    deleteAuth: 'Konfirmasi pembatalan otoriasi?',
  },
  notify: {
    operateSuccess: 'Berhasil diproses',
    copySuccess: 'Berhasil disalin',
    // noAddress: 'Tidak Ada Alamat',
    error: {
      accountFormat: 'Format akun Bank salah',
    },
    orderTimeout1: 'Pesanan akan di proses',
    orderTimeout2: 'Setelah kadaluarsa, harap bayar tepat waktu',
    timeOut: 'Waktu Habis',
    noSubordinates: 'Tidak memiliki Subordinasi',
    insufficientAvailableBalance: 'Saldo yang tersedia tidak mencukupi',
    loginAgain: 'Masuk Kembali!',
    matchPassword: 'Kata sandi Cocok',
    getAuth: ' Dapatkan ijin berikut',
  },
  status: {
    opened: 'Terbuka',
    unopened: 'Belum dibuka',
    reviewFailed: 'Tinjauan gagal',
    inReview: 'Sedang ditinjau',
    withdrawSuccess: 'Penarikan Sukses',
    returned: 'Dikembalikan',
    notReturned: 'Belum',
  },
  home: {
    getRebate: 'Dapatkan Bonus',
  },
  tier: {
    currentTier: 'Your Current Tier',
    memberSinceTime: 'Member since [[1]]',
    levelBtn: 'Tier levels',
    benefitBtn: 'Tier Benefits',
    congratulations: 'Congratulations on your rank achievement',
  },
}

export default lang;