// vant 国际化
import { Locale } from 'vant';
import zhCN from 'vant/lib/locale/lang/zh-CN';
import enUS from 'vant/lib/locale/lang/en-US';
import idID from 'vant/lib/locale/lang/id-ID';
 
function changeLang(newlang) {
  let vantLang = newlang.replace('_', '-');
  switch (vantLang) {
    case 'zh-CN':
      Locale.use(vantLang, zhCN);
      localStorage.setItem('i18n', newlang)
      break;
    case 'in-ID':
      Locale.use(vantLang, idID);
      localStorage.setItem('i18n', newlang)
      break;
    default:
      Locale.use('en-US', enUS);
      localStorage.setItem('i18n', 'en_US')
      break;
  }
}
 
export function Local(mylang) {
  changeLang(mylang)
}