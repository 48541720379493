<template>
  <van-config-provider :theme-vars="themeVars">
    <div :class="`app-content theme${$AppData.config.themeType}`">
      <router-view v-if="isRouterAlive" />
      <!-- <ringmenu v-show="showMenu" :quickMenu="store.quickMenu" /> -->
    </div>
  </van-config-provider>
</template>
<script>
import { getCurrentInstance } from 'vue';
import ringmenu from '@/components/RingMenu.vue';
import { store } from '@/store';

export default {
  components: {
    ringmenu,
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const globalProperties = internalInstance ? internalInstance.appContext.config.globalProperties : {};
    
    const themeVars = globalProperties.$AppData.config.themeType ? {
      navBarBackgroundColor: '#fff',
      navBarTitleTextColor: globalProperties.$primary,
      navBarTitleFontSize: '17px',
      navBarIconColor: globalProperties.$primary,
    } : {};
    return { themeVars };
  },
  provide () {
    return { 
      reload: this.reload
    }
  },
  data () {
    return {
      store,
      isRouterAlive: true,
    }
  },
  computed: {
    showMenu() {
      const hideList = ['login', 'register', 'forgetPassword'];
      if (hideList.indexOf(this.$route.name) > -1) {
        return false;
      }
      if (store.quickMenu.length <= 0) {
        return false;
      }
      return true;
    }
  },
  created() {
    document.addEventListener('visibilitychange', () => {
      store.setDocumentVisibility(!document.hidden);
    });
  },
  mounted() {
    // store.fetchQuickMenu();
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  }
};
</script>
<style lang="less">
@import './assets/font/iconfont.css';
@import './assets/font/font.css';

body {
  font-size: 16px;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

.app-content {
  min-height: 100vh;
  background-color: #f8f7fc;
  &.theme1 {
    background: @background-color;
  }
}

p { margin: 0; }
.van-button--disabled {
  color: #333 !important; 
  background: #c1c1c1 !important; 
  border-color: #c1c1c1;
}
:root {
  --van-primary-color: @primary-color;
  --light-primary-color: #f9f4ed;
  --van-password-input-height: 45px;
  --van-nav-bar-icon-color: #333;
  --van-field-placeholder-text-color: #C1C1D6;
  --van-toast-text-color: #3d3d3d;
  --van-toast-loading-icon-color: #3d3d3d;
  --van-toast-background-color: #fff;
}
.van-toast {
  width: 300px;
  box-shadow: 0px 0px 8px 0px #ccc;
  &.van-toast--loading {
    width: 88px;
  }
  .van-toast__text{
    word-break:break-word;
  }
}
.my-dialog-icon {
  width: 46px;
}
.my-dialog-text {
  margin-top: 20px;
  color: var(--van-primary-color);
  font-size: 17px;
  line-height: 24px;
}
.van-dialog {
  .van-action-bar-button--first {
    margin-bottom: 10px;
    color: var(--van-primary-color);
    background: #fff;
    border: 1px solid var(--van-primary-color);
    border-radius: 18px;
    margin-right: 20px;
  }
  .van-action-bar-button--last {
    margin-bottom: 10px;
    background: var(--van-primary-color);
    border: 1px solid var(--van-primary-color);
    border-radius: 18px;
  }
}

.van-tabs__line {
  background: var(--van-primary-color);
}
.van-tab--line {
  &.van-tab--active {
    color: @primary-color;
  }
  .van-tab__text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
  
.van-tabs--card>.van-tabs__wrap {
  height: 40px;
}
.van-tabs__nav--card {
  height: 40px;
  padding-bottom: 0;
  border-radius: 8px 8px 0 0;
  border: none;
  background: @background-color;
}
.van-tab--card {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: @primary-color;
  background: transparent;
  border-radius: 8px 8px 0 0;
  border-right: #ccc;
  box-sizing: border-box;
  &.van-tab--active {
    color: @primary-color;
    background: #fff;
  }
}
.theme1 {
  .van-tabs__line {
    width: 30%;
    background: @light-primary-color;
  }
  .van-tab--line {
    padding: 0 3px;
    font-size: 13px;
    color: @primary-color;
  }
}
.order-list {
  padding: 16px;
  .order-block {
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    line-height: 30px;
    padding: 6px 12px;
    margin-bottom: 12px;
    font-size: 15px;
    background: #fff;
    border-radius: 6px;
    .order-sn {
      width: 60%;
    }
    .order-amount {
      width: 40%;
      text-align: right;
    }
    .order-time {
      width: 60%;
      font-size: 13px;
      color: #C1C1D6;
    }
    .order-status {
      width: 40%;
      text-align: right;
      color: blue;
    }
  }
  
  .journal-block {
    margin-bottom: 10px;
    box-shadow: 0px 4px 16px 0px #EBEBEB;
    .van-cell-group {
      background: transparent;
    }
    .journal-title {
      font-size: 12px;
      color: #6F6F93;
      padding: 6px 14px;
      border-radius: 6px 6px 0 0;
      .van-icon {
        margin-right: 6px;
      }
      .journal-sn {
        color: #6F6F93;
        font-size: 11px;
      }
    }
    .journal-detail {
      padding: 10px 14px;
      border-radius: 0 0 6px 6px;
      background: #fff;
    }
    .journal-detail-row {
      padding: 0;
      font-size: 12px;
      background: transparent;
      .van-cell__title {
        color: #28293D;
        font-weight: 600;
       }
      .van-cell__value { color: #333; }
    }
    .order-date {
      padding: 6px 14px;
      font-size: 12px;
      color: #6F6F93;
      background: #f9f9f9;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      .order-status {
        color: #6F6F93;
        &::before {
          display: inline-block;
          content: '';
          width: 4px;
          height: 4px;
          margin-right: 4px;
          background: #FF8F00;
          border-radius: 50%;
          vertical-align: middle;
        }
        &.green::before {
          background: #1BA27A;
        }
        &.red::before {
          background: #FF4060;
        }
        &.warn::before {
          background: #FF8F00;
        }
      }
    }
    .btn-wrap {
      display: flex;
      justify-content: center;
      padding: 10px;
      background: #f9f9f9;
      border-radius: 0 0 2px 2px;
      .van-button {
        margin: 0 16px;
        min-width: 80px;
      }
    }
  }
}
.theme1 {
  .order-list {
    padding: 18px;
    .order-block {
      background: @background-color;
      border-radius: 9px;
      &.white { background: #fff; }
      .order-sn {
        font-weight:700;
        color: @font-color;
        font-size: 14px;
        border-bottom: 1px solid #d8d8d8;
      }
      .order-amount {
        color: @font-color;
        font-size: 13px;
        border-bottom: 1px solid #d8d8d8;
      }
      .order-time {
        width: 50%;
        color: @light-font-color;
      }
      .order-status {
        width: 50%;
        font-weight: 500;
        color: #174372;
        font-size: 12px;
        &.green { color: #1BA27A; }
        &.red { color: #FF4060; }
        &.warn { color: #FF8F00; }
      }
    }
    
    .journal-block {
      position: relative;
      margin-bottom: 15px;
      padding: 6px 12px;
      border-radius: 9px;
      background: @background-color;
      box-shadow: none;
      &.white {
        background: #fff;
      }
      .van-cell-group {
        background: transparent;
      }
      .journal-title {
        font-size: 13px;
        padding: 8px 0;
        line-height: 20px;
        border-bottom: 1px solid #d8d8d8;
        // border-radius: 9px 9px 0 0;
        background: transparent;
        .van-cell__title {
          flex: 2;
          font-size: 14px;
          color: @font-color;
          font-weight: 700;
         }
        .van-cell__value { 
          flex: 3;
          font-size: 13px;
          color: @light-font-color;
        }
      }
      .journal-detail {
        padding: 0;
        background: transparent;
        .water-mark {
          position: absolute;
          top: 48px;
          right: 48px;
          font-size: 26px;
          font-weight: bold;
          color: rgba(182, 182, 182, 0.25);
          transform: rotateZ(-20deg);
          &.green {
            color: rgba(36, 207, 155, 0.25);
          }
          &.red {
            color: rgba(255, 92, 120, 0.25);
          }
          &.warn {
            color: #FF8F00;
          }
        }
      }
      .journal-detail-row {
        font-size: 13px;
        padding: 8px 0;
        line-height: 20px;
        border-bottom: 1px solid #d8d8d8;
        &:last-child {
          border-bottom: none;
        }
        .van-cell__title {
          font-size: 14px;
          color: @font-color;
          font-weight: 700;
         }
        .van-cell__value { color: @light-font-color; }
      }
      .btn-wrap {
        padding: 10px 10px 16px;
        background: transparent;
        border-radius: 0 0 9px 9px;
        .van-button {
          height: 36px;
          font-size: 16px;
          font-weight: bold;
          padding: 0 18px;
        }
      }
    }
  }
} 


// .shake {
//   animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
//   transform: translate3d(0, 0, 0);
// }
// @keyframes shake {
//   10%,
//   90% {
//     transform: translate3d(-1px, 0, 0);
//   }
//   20%,
//   80% {
//     transform: translate3d(2px, 0, 0);
//   }
//   30%,
//   50%,
//   70% {
//     transform: translate3d(-4px, 0, 0);
//   }
//   40%,
//   60% {
//     transform: translate3d(4px, 0, 0);
//   }
// }
</style>
