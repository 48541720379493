import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import 'lib-flexible';
import moment from 'moment';
import i18n, { Local } from '@/assets/lang/index';
import dir from '@/directive/index';
import precision from '@/common/precision';
import MyToast from '@/common/MyToast';
import MyDialog from '@/common/MyDialog';
import { queryAppInfo } from "@/api";
import {
  Col,
  Row,
  Icon,
  Tag,
  NavBar,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Image,
  Cell,
  CellGroup,
  List,
  Form,
  Field,
  Button,
  Badge,
  Picker,
  Radio,
  RadioGroup,
  Calendar,
  Progress,
  TreeSelect,
  Divider,
  Popup,
  Empty,
  PullRefresh,
  Sticky,
  Toast,
  Notify,
  Dialog,
  ActionSheet,
  CountDown,
  Uploader,
  PasswordInput,
  NumberKeyboard,
  Lazyload,
  ConfigProvider
} from 'vant';

const app = createApp(App);
app.use(router);
app.use(Col);
app.use(Row);
app.use(Icon);
app.use(Tag);
app.use(NavBar);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Tab);
app.use(Tabs);
app.use(Image);
app.use(Cell);
app.use(CellGroup);
app.use(List);
app.use(Form);
app.use(Field);
app.use(Button);
app.use(Badge);
app.use(Picker);
app.use(Radio);
app.use(RadioGroup);
app.use(Calendar);
app.use(Progress);
app.use(TreeSelect);
app.use(Divider);
app.use(Popup);
app.use(Empty);
app.use(PullRefresh);
app.use(Sticky);
app.use(Toast);
app.use(Notify);
app.use(Dialog);
app.use(ActionSheet);
app.use(CountDown);
app.use(Uploader);
app.use(PasswordInput);
app.use(NumberKeyboard);
app.use(Lazyload);
app.use(ConfigProvider);
app.use(i18n);
app.directive('preventRepeat', dir.preventRepeat);

Toast.allowMultiple();

app.config.globalProperties.$primary = process.env.VUE_APP_PRIMARY;
app.config.globalProperties.$toast = MyToast;
app.config.globalProperties.$myDialog = MyDialog;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$cookie = {
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  },
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1);
      if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
  },
  clearCookie(name) {
    this.setCookie(name, "", -1);  
  },
};
app.config.globalProperties.$AppData = {
  config: {},
  setConfig(config) {
    this.config = config || {};
  },
  saveConfig(data) {
    this.config = {
      ...this.config,
      ...data,
    };
  },
  dataStore: {},
  setDataStore(data) {
    this.dataStore = data || {};
  },
  saveDataStore(data) {
    this.dataStore = {
      ...this.dataStore,
      ...data,
    };
  },
  clearDataStore() {
    this.dataStore = {};
  },
  pageMapping: {
    'HomeFragment': '/home',
    'WorkbenchFragment': '/workbench',
    'ShowFragment': '/show', 
    'MineFragment' :'/mine',
    
    'COIN_BUY': '/coin/recharge',
    'COIN_SELL': '/coin/withdraw',
    'LEAGL_BUY': '/legal/recharge',
    'LEGAL_CHANNEL_BUY': '/legal/channelrecharge',
    'WITHDRAW': '/legal/withdraw',
    'USER_ADDRESS':'/userAddress',
    
    
    'MARKET': '/market',
    'IN_PROCESS': '/order/inprocess',
    'COMPLETED': '/order/completed',
    'REMINDER': '/order/reminder',
    
    'SELLER_ACCOUNT': '/mine/receiveaddress',
    'CONTRIBUTION': '/contribution/record',
    'CONTRIBUTION_CARD': '/contribution/card',
    'MY_SUBORDINATE': '/subordinate',
    'MULTI_LEVEL_MANAGE': '/subordinate',
    'USER_BANK': '/bankRecharge',
    'USER_WITHDRAW_BANK': '/bankWithdrawal',
    'EARNEST': '/earnest',

    'PAYMENT_BUY': '/payment/buy',
    
    'ONLINE_SERVICE': '/onlineService',
    'AUTHORIZATION': '/authorization',
    'TASK_CENTER': '/task',
    'MODIFY_LOGIN_PASSWORD': '/password/modify',
    'SWITCH_LANGUAGE': '/language',
    
    'GAME': '/game',
    'PROMOTION': '/promotion',
  },
};

queryAppInfo().then(res => {
  let appConfig = res.data || {};
  appConfig = {
   ...appConfig,
   appPageInfoList: appConfig.appPageInfoList?.sort((a, b) => a.tab - b.tab) || [],
   themeType: parseInt(appConfig.theme),
  }
  app.config.globalProperties.$AppData.setConfig(appConfig);
  
  let lang;
  if (!localStorage.getItem('i18n')) {
    lang = appConfig.defaultLocal || 'en_US';
  } else {
    lang = localStorage.getItem('i18n');
  }
  Local(lang);
  
  app.mount('#app');
});


Number.prototype.upFixed = function (fix) {
  // num为原数字，fix是保留的小数位数
  let num = this;
  let result = '0'
  if (Number(num) && fix > 0) { // 简单的做个判断
    fix = +fix || 2
    num = num + ''
    if (/e/.test(num)) { // 如果是包含e字符的数字直接返回
      result = num
    } else if (!/\./.test(num)) { // 如果没有小数点
      result = num + `.${Array(fix + 1).join('0')}`
    } else { // 如果有小数点
      num = num + `${Array(fix + 1).join('0')}`
      let reg = new RegExp(`-?\\d*\\.\\d{0,${fix}}`)
      let floorStr = reg.exec(num)[0]
      if (+floorStr >= +num) {
        result = floorStr
      } else {
        let floorNumber = +floorStr + +`0.${Array(fix).join('0')}1`
        let point = /\./.test(floorNumber + '') ? '' : '.'
        let floorStr2 = floorNumber + point + `${Array(fix + 1).join('0')}`
        result = reg.exec(floorStr2)[0]
      }
    }
  }
  return result;
};

Number.prototype.downFixed = function (fix) {
  let strs = this.toString().split(".")
  let length = strs[1] ? strs[1].length : 0;
  if(length >= fix) {
    return strs[0] + '.' + strs[1].slice(0,fix);
  } else {
    return this;
  }
};

String.prototype.downFixed = function (fix) {
  let strs = this.toString().split(".")
  let length = strs[1] ? strs[1].length : 0;
  if(length >= fix) {
    return strs[0] + '.' + strs[1].slice(0,fix);
  } else {
    return this;
  }
};

String.prototype.format = function() {
  if(arguments.length == 0) return this;
  var obj = arguments[0];
  var s = this;
  for(var key in obj) {
    s = s.replace(new RegExp("\\[\\[" + key + "\\]\\]", "g"), obj[key]);
  }
  return s;
}

let u = navigator.userAgent;
let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
if(isAndroid) {//处理安卓输入框聚焦时被遮盖问题
   window.addEventListener('resize', function() {
    if(document.activeElement.tagName=='INPUT' || document.activeElement.tagName=='TEXTAREA') {
      window.setTimeout(function() {
        document.activeElement.scrollIntoView();
      }, 0);
    }
   })
}

