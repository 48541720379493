export default {
  preventRepeat: {
    mounted(el, binding){
      el.addEventListener('click', ()=>{
        if(!el.disabled){
          el.disabled = true;
          el.style.cursor = 'not-allowed';
          setTimeout(()=>{
            el.disabled = false;
            el.style.cursor = 'pointer';
          }, binding.value || 500);
        }
      })
    }
  }
}