<template>
  <div class="ring-menu">
    <div
      class="menu-center-wrap"
      :style="positionStyle"
      ref="dragRingMenuIcon"
      @touchstart.stop="touchstartHandle('dragRingMenuIcon',$event, true)" 
      @touchmove.stop.prevent="touchmoveHandle('dragRingMenuIcon',$event, true)"
      @touchend.stop.prevent="touchendHandle('dragRingMenuIcon',$event, true)"
      @click="toggleRingMenu"
    >
      <van-badge v-if="centerIconBadge" dot>
        <van-image src="/images/ringmenu/center-menu.png" />
      </van-badge>
      <van-image v-else src="/images/ringmenu/center-menu.png" />
    </div>
    <van-popup :class="position" v-model:show="showRingMenu" :style="positionStyle">
      <div class="ring-menu-wrap" :style="menuStyle">
        <div 
          v-for="menu in quickMenu"
          class="menu-item-wrap" 
          @click="menuClick(menu)"
        >
          <van-badge v-if="menu.data" class="menu-icon"  dot>
            <van-image :src="menu.iconUrl" />
          </van-badge>
          <van-image v-else class="menu-icon" :src="menu.iconUrl" />
          <div class="menu-label">{{menu.name}}</div>
        </div>
        <div class="ring-bg"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useWindowSize, useRect } from '@vant/use';

export default {
  name: 'RingMenu',
  props: [ 'quickMenu' ],
  setup() {
    const { width, height } = useWindowSize();
    
    return {
      width,
      height,
    }
  },
  data() {
    return {
      showRingMenu: null,
      originalStatus: null,
      position: 'right',
      positionStyle: 'right: 0px; top: 240px;',
      coordinate: {
        client: {},
        elePosition: {},
      },
    };
  },
  computed: {
    menuStyle() {
      if(this.showRingMenu === null) {
        return ''; 
      }
      return `transform: scale(${this.showRingMenu ? 0.1 : 1}); animation-name: ${this.showRingMenu ? 'scaleShow' : 'scaleHide'};`
    },
    centerIconBadge() {
      let total = 0;
      this.quickMenu.map(menu => {
        total = total + menu.data;
      });
      return total;
    },
  },
  
  mounted() {},

  methods: {
    touchstartHandle(refName, e, flag) { // 传false不拖拽
      if (!flag) {
        return false;
      };
      this.originalStatus = this.showRingMenu;
      if(this.showRingMenu) {
        this.showRingMenu = false;
      }
      let element = e.targetTouches[0];
      
      // 记录点击的坐标
      this.coordinate.client = {
        x: element.clientX,
        y: element.clientY
      };
      // 记录需要移动的元素坐标
      this.coordinate.elePosition.left = this.$refs[refName].offsetLeft;
      this.coordinate.elePosition.top = this.$refs[refName].offsetTop;
    },
    touchmoveHandle(refName, e, flag) {
      if (!flag) {
        return false;
      };
      let element = e.targetTouches[0];
      // 根据初始 client 位置计算移动距离(元素移动位置=元素初始位置+光标移动后的位置-光标点击时的初始位置)
      let x = this.coordinate.elePosition.left + (element.clientX - this.coordinate.client.x);
      let y = this.coordinate.elePosition.top + (element.clientY - this.coordinate.client.y);
      // 限制可移动距离，不超出可视区域
      let leftLimit = 0;
      let rightLimit = innerWidth - this.$refs[refName].offsetWidth;
      let bottomLimit = innerHeight - this.$refs[refName].offsetHeight;
      x = x <= leftLimit ? leftLimit : x >= rightLimit ? rightLimit : x;
      y = y <= 0 ? 0 : y >= bottomLimit ? bottomLimit : y;
      // 移动当前元素
      this.$refs[refName].style.left = x + 'px';
      this.$refs[refName].style.top = y + 'px';
    },
    touchendHandle(refName, e, flag) {
      if (!flag) {
        return false;
      };
      let ringRect = useRect(this.$refs[refName]);
      // console.log(ringRect);
      let distanceX = ringRect.x + ringRect.width / 2, distanceY = ringRect.y + ringRect.height / 2;
      let positionX = 'left', positionY = 'top', position;
      if(distanceX > this.width / 2) {
        distanceX = this.width - distanceX;
        positionX = 'right';
      }
      if(distanceY > this.height / 2) {
        distanceY = this.height - distanceY;
        positionY = 'bottom'
      }
      if (distanceY < distanceX) {
        position = positionY;
      } else {
        position = positionX;
      }
      // console.log('distanceX:', distanceX);
      // console.log('distanceY:', distanceY);
      // console.log(position);
      let x = ringRect.x;
      let y = ringRect.y;
      switch (position){
        case 'top':
          this.positionStyle = `top: ${0}px; left: ${x}px;`;
          break;
        case 'bottom':
          this.positionStyle = `bottom: ${0}px; left: ${x}px;`;
          break;
        case 'right':
          this.positionStyle = `right: ${0}px; top: ${y}px;`;
          break;
        default:
          this.positionStyle = `left: ${0}px; top: ${y}px;`;
          break;
      }
      this.position = position;
      // 判断拖动距离 小于5像素 触发click事件
      if (Math.abs(x - this.coordinate.elePosition.left) < 5 
        && Math.abs(y - this.coordinate.elePosition.top) < 5
        && !this.originalStatus) {
        this.toggleRingMenu();
      }
    },
    toggleRingMenu() {
      if(this.showRingMenu) {
        this.showRingMenu = false;
      } else {
        this.showRingMenu = true;
      }
    },
    menuClick(menu) {
      this.$router.push(this.$AppData.pageMapping[menu.code]);
      this.showRingMenu = false;
      if (menu.code === 'TASK_CENTER') {
        localStorage.setItem('pageName', menu.name);
      }
    },
  },
};
</script>

<style lang="less">
.ring-menu{
  .menu-center-wrap {
    position: fixed;
    width: 40px;
    height: 40px;
    padding: 10px;
    box-sizing: content-box;
    z-index: 100;
  }
  .van-overlay {
    z-index: 99 !important;
  }
  .van-popup {
    position: fixed;
    left: auto;
    top: auto;
    background: transparent;
    transform: none;
    z-index: 99 !important;
  }
  .ring-menu-wrap {
    position: relative;
    width: 228px;
    height: 228px;
    transform: scale(0.1);
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-fill-mode: both;
    .ring-bg {
      width: 228px;
      height: 228px;
      opacity: 0.8;
      border: 70px solid #fff;
      border-radius: 50%;
      box-sizing: border-box;
    }
    .menu-item-wrap {
      position: absolute;
      width: 60px;
      z-index: 1;
      .menu-icon {
        display: block;
        width: 36px;
        height: 36px;
        margin: 0 auto 2px;
      }
      .menu-label {
        height: 14px;
        line-height: 14px;
        color: #333;
        font-size: 10px;
        text-align: center;
        letter-spacing: -0.5px;
      }
    }
  }
  .van-popup.left {
    padding-top: 60px;
    padding-left: 38px;
    transform: translate3d(-50%, -50%, 0);
    .ring-bg {
      clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
      -webkit-clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
    }
    .menu-item-wrap {
      &:nth-child(1) {
        left: calc(114px + 10px);
        top: 18px;
      }
      &:nth-child(2) {
        left: calc(114px + 48px);
        top: 89px;
      }
      &:nth-child(3) {
        left: calc(114px + 10px);
        top: 154px;
      }
    }
  }
  .van-popup.right {
    padding-top: 60px;
    padding-right: 38px;
    transform: translate3d(50%, -50%, 0);
    .ring-bg {
      clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
      -webkit-clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
    }
    .menu-item-wrap {
      &:nth-child(1) {
        right: calc(114px + 10px);
        top: 18px;
      }
      &:nth-child(2) {
        right: calc(114px + 48px);
        top: 89px;
      }
      &:nth-child(3) {
        right: calc(114px + 10px);
        top: 158px;
      }
    }
  }
  .van-popup.top {
    padding-top: 38px;
    padding-left: 60px;
    transform: translate3d(-50%, -50%, 0);
    .ring-bg {
      clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
      -webkit-clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
    }
    .menu-item-wrap {
      &:nth-child(1) {
        top: calc(114px + 10px);
        left: 15px;
      }
      &:nth-child(2) {
        top: calc(114px + 50px);
        left: 84px;
      }
      &:nth-child(3) {
        top: calc(114px + 10px);
        left: 151px;
      }
    }
  }
  .van-popup.bottom {
    padding-bottom: 38px;
    padding-left: 60px;
    transform: translate3d(-50%, 50%, 0);
    .ring-bg {
      clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
    }
    .menu-item-wrap {
      &:nth-child(1) {
        bottom: calc(114px + 10px);
        left: 10px;
      }
      &:nth-child(2) {
        bottom: calc(114px + 52px);
        left: 84px;
      }
      &:nth-child(3) {
        bottom: calc(114px + 10px);
        left: 155px;
      }
    }
  }
  .van-badge--dot {
    width: 10px;
    height: 10px;
    right: 5px;
    top: 4px;
  }
  @keyframes scaleShow {
    100% {
      transform: scale(1);
    }
  }
  @keyframes scaleHide {
    100% {
      transform: scale(0.1);
    }
  }
}
</style>
