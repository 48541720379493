import axios from "axios"; // 引用axios
import { Toast } from 'vant';
import { router } from '@/router';
import i18n from '@/assets/lang/index';
import MyToast from '@/common/MyToast';

const instance = axios.create({
  // baseURL: config.baseUrl.dev,
  timeout: 10000,
});

let loading;
//正在请求数量
let requestCount = 0;
//显示loading
const showLoading = () => {
  if (requestCount === 0 && !loading) {
    loading = Toast.loading({
      message: i18n.global.t('common.loading') + '...',
      forbidClick: true,
      duration: 0,
    });
  }
  requestCount++;
}

const hideLoading = () => {
  requestCount--;
  if (requestCount === 0) {
    loading.clear();
    loading = '';
  }
}

//请求拦截器 
instance.interceptors.request.use((config) => {
  if(!config.noLoading) {
    showLoading();
  }
  config.headers = {
    ...config.headers,
    lang: localStorage.getItem('i18n') || 'en_US',
  };
  return config;
}, (error) => {
  // 对请求错误做些什么
  return Promise.reject(error)
});

//响应拦截器
instance.interceptors.response.use((response) => {
  if(!response.config.noLoading) {
    hideLoading();
  }
  //响应成功
  if (response.data.status !== 0) {
    setTimeout(() => { //延迟展示Toast信息  修复Toast信息被Loading挡住的问题
      MyToast({ type: 'danger', message: response.data.msg || 'Error!' });
    }, 100);
    // MyToast({ type: 'danger', message: response.data.msg || 'Error!' });
    if(response.data.status === 4 || response.data.status === 5 ){
      router.replace('/login');
    }
    return Promise.reject(response);
  } else {
    if (response.data.msg && response.config.method !== 'get') {
      setTimeout(() => { //延迟展示Toast信息  修复Toast信息被Loading挡住的问题
        MyToast({ type: 'success', message: response.data.msg });
      }, 100);
      // MyToast({ type: 'success', message: response.data.msg });
    } 
  }
  return response.data;
}, (error) => {
  hideLoading();
  let message = 'Request error';
  //响应错误
  if(error.response && error.response.status){
    const status = error.response.status
    switch (status) {
      case 400:
        message = 'Request error'; //请求错误
        break;
      case 401:
        message = 'Request error'; //请求错误
        break;
      case 404:
        message = 'Request address error'; //请求地址出错
        break;
      case 408:
        message = 'Request timeout'; //请求超时
        break;
      case 500:
        message = 'Server error!'; //服务器内部错误
        break;
      case 501:
        message = 'Service Unavailable!'; //服务未实现
        break;
      case 502:
        message = 'Bad gateway!'; //网关错误
        break;
      case 503:
        message = 'Service Unavailable!'; //服务不可用
        break;
      case 504:
        message = 'Gateway timeout!'; //网关超时
        break;
      case 505:
        message = 'HTTP is not supported'; //HTTP版本不受支持
        break;
      default:
        message = 'Request error'; //请求失败
    }
  }
  MyToast({ type: 'danger', message });
  return Promise.reject(error);
});

export default instance;