const lang = {
  common: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    today: 'Today',
    noData: 'No Data',
    noMore: 'No More',
    loading: 'Loading',
    downloading: 'Downloading',
  },
  placeholder: {
    default: 'Please Enter',
    optional: 'Optional',
    phone: 'Enter Phone Number',
    verifyCode: 'OTP',
    password: 'Enter Your Password',
    inviteCode: 'Enter Invitation Code',
    oldPwd: 'Enter Current Password',
    newPwd: 'Enter New Password',
    newPwdAgain: 'Confirm New Password',
    minPrice: 'MIN Amount',
    maxPrice: 'MAX Amount',
  },
  field: {
    available: 'Balance',
    available2: 'Available',
    frozen: 'Pending Amount',
    income: 'My Profit',
    todayIncome: 'Today’s Profit',
    buy: 'Buy ',
    payAmount: 'Pay Amount',
    receivedAmount: 'Received Amount',
    exchangeRate: 'Exchange Rate',
    appraisal: 'Convert',
    commission: 'Service Charge',
    bankCardNumber: 'Bank Account',
    bankName: 'Bank Name',
    payee: 'Payee',
    payer: 'Payer',
    remark: 'Remark',
    cardholder: 'Holder Name',
    verifyCode: 'OTP',
    amount: 'Amount',
    address: 'Address',
    balance: 'Balance',
    availableBalance: 'Available Balance ',
    orderNumber: 'Order No.',
    orderTime: 'Transaction Time',
    contribution: 'Order Credits',
    income2: 'Profit',
    account: 'Account Name',
    needDeposit: 'Need Deposit',
    dayContribution: 'Daily Order Credits',
    purchaseTime: 'Purchase time',
    deposit: 'Deposit',
    depositStatus: 'Deposit Status',
    depositRefundTime: 'Refund Time',
    status: 'Status',
    useDay: 'Valid Day | Valid Days',
    usingDay: 'Days Used',
    usingTime: 'Active Time',
    type: 'Type',
    realname: 'Real Name',
    failMsg: 'Failure Reason',
    cancelMsg: 'Cancellation Reason',
    teamTurnover: 'Team Transaction Amount',
    teamOrders: 'Team Transaction Numbers',
    lastTradingTime: 'Last Transaction Time',
    invitationCodeRate: 'Invitation Code Rate',
    startTime: 'Start Time',
    endTime: 'End Time',
    orderCredits: 'Order Credits',
    securityDeposit: 'Security Deposit',
    minimumSecurityDeposit: 'Minimum Security Deposit',
    expiring: 'Expiring Soon',
    channel: 'Channel',
    request: 'Request',
  },
  title: {
    login: 'Login',
    register: 'Register',
    historyData: 'History',
    pay: 'Pay',
    rechargeDetail: 'Recharge Order Details',
    success: 'Withdraw Successful',
    buyCoinBankcard: 'Add Recharge Bank Account',
    withdrawalBankcard: 'Bank Account',
    detail: 'Details',
    workbench: 'Statistics',
    accountDetail: 'Account Details',
    expirationTime: 'Expiration Time: [[1]] mins',
    priceFilter: 'Amount Range',
    market: 'Dashboard',
    userAddress: 'Address Book',
    order: 'My Order',
  },
  tabTitle: {
    buyCoins: 'Buy Coin',
    rechargeProcessing: 'Pending Payment',
    withdrawProcessing: 'Status',
    success: 'Completed',
    failed: 'Failed',
    haveInHand: 'In Process',
    expired: 'Expired',
    receiptFragment: 'Market',
    contact: 'Mine',
    cCardPurchase: 'Purchased History',
    cCardPurchaseUsing: 'Effective Cards',
    cCardPurchaseIsUsed: 'Expired Cards',
    withdrawal: 'Withdrawal',
    recharge: 'Recharge',
    processing: 'Processing',
  },
  button: {
    login: 'Login',
    logout: 'Logout',
    register: 'Register',
    goToLogin: 'Login',
    goToRegister: 'Sign Up',
    smsBtn: 'Send OTP',
    smsResend: 'Resend',
    smsColdDown: 'Reacquired after',
    second: ' S',
    pwdLogin: 'Password login',
    otpLogin: 'OTP Login',
    forgetPwd: 'Forgot Password',
    finish: 'Finish',
    close: 'Turn OFF',
    open: 'Turn On',
    turnOffAuto: 'Turn OFF Auto',
    turnOnAuto: 'Turn On Auto',
    submit: 'Submit',
    confirm: 'Confirm',
    nextBtn: 'Next',
    continueWithdraw: 'Continue  To Withdraw Cash',
    back: 'Back',
    orderReceiving: 'Collect',
    completed: 'Completed',
    done: 'Done',
    fail: 'Fail',
    share: 'Share',
    purchase: 'Purchase',
    purchased: 'Purchased',
    notReachRank: 'Not for current tier',
    soldOut: 'Sold out',
    authorization: 'Authorize', 
    refund: 'Refund',
    uploadCertificate: 'Upload Certificate',
  },
  alert: {
    default: 'Reminder',
    warnning: 'Prompt',
    rechargeSuccessful: 'Is The Recharge Successful?',
    hasReceived: 'Has [[1]] Received [[2]][[3]]?',
    notReceived: 'Are You Sure Money Not Received?',
    deductContribution: 'Deduct Order Credits',
    cancel: 'Confirm To Cancel?',
    close: 'Confirm To Close?',
    open: 'Confirm To Open?',
    purchase: 'Confirm Purchase?',
    deleteBankCard: 'Do You Want To Delete This Bank Card?',
    addRechargeBankCard: 'Please Add The Recharge Bank Card To Do This Again',
    updateVersion: 'Update To Latest Version?',
    logout: 'Confirm To Sign Out?',
    betOrderCancel: 'Are you sure to cancel your bet?',
    betOrderBatchCancel: 'Are you sure to cancel all bets?',
    deleteAuth: 'Confirm deauthorization?',
    addSecurityDeposit: 'Confirm add security deposit?',
    refundSecurityDeposit: 'Confirm refund of security deposit?',
    buyPayment: 'Confirm purchase [[1]][[2]]',
  },
  notify: {
    operateSuccess: 'Successfully Processed',
    copySuccess: 'Copy Successfully',
    sentSuccess: 'Sent Successfully',
    error: {
      accountFormat: 'Your Bank Account Number Format Is Incorrect.',
    },
    orderTimeout1: 'The Order Will Expire In ',
    orderTimeout2: '. Please Pay In Time.',
    timeOut: 'Time Out',
    noSubordinates: 'No Subordinates',
    insufficientAvailableBalance: 'Insufficient Available Balance',
    loginAgain: 'Login Has Expired, Please Log In Again!',
    matchPassword: 'The New Password Entered Twice Does Not Match',
    getAuth: 'Get the permissions',
  },
  status: {
    opened: 'ON',
    unopened: 'OFF',
    reviewFailed: 'Audit Failure',
    inReview: 'Under Review',
    withdrawSuccess: 'Successful Withdrawal',
    returned: 'Returned',
    notReturned: 'Not Returned',
  },
  home: {
    getRebate: ' Got Order Benefit ',
  },
  tier: {
    currentTier: 'Your Current Tier',
    memberSinceTime: 'Member since [[1]]',
    levelBtn: 'Tier levels',
    benefitBtn: 'Tier Benefits',
    congratulations: 'Congratulations on your rank achievement',
  },
}

export default lang;
