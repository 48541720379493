const lang = {
  common: {
    add: '添加',
    edit: '编辑',
    delete: '删除',
    cancel: '取消',
    today: '今日',
    noData: '暂无数据',
    noMore: '没有更多了',
    loading: '加载中',
    downloading: '下载中',
  },
  placeholder: {
    default: '请输入',
    optional: '可选',
    phone: '请输入手机号',
    verifyCode: '请输入验证码',
    password: '请输入密码',
    inviteCode: '请输入邀请码',
    oldPwd: '请输入原密码',
    newPwd: '请输入新密码',
    newPwdAgain: '再次输入新密码',
    minPrice: '最小金额',
    maxPrice: '最大金额',
  },
  field: {
    available: '可用',
    available2: '可用',
    frozen: '接单中',
    income: '收益',
    todayIncome: '今日收益',
    buy: '购买',
    payAmount: '支付金额',
    receivedAmount: '到账金额',
    exchangeRate: '汇率',
    appraisal: '估价',
    commission: '手续费',
    bankCardNumber: '银行卡号',
    bankName: '银行名称',
    payee: '收款人',
    payer: '付款人',
    remark: '备注',
    cardholder: '持卡人',
    verifyCode: '验证码',
    amount: '金额',
    address: '地址',
    balance: '余额',
    availableBalance: '可用余额',
    orderNumber: '订单号',
    orderTime: '订单时间',
    contribution: '贡献值',
    income2: '预估差价',
    account: '账号',
    needDeposit: '需缴纳押金',
    dayContribution: '每日发放接单值',
    purchaseTime: '购买时间',
    deposit: '押金',
    depositStatus: '押金状态',
    depositRefundTime: '押金返还时间',
    status: '状态',
    useDay: '可使用天数',
    usingDay: '已使用天数',
    usingTime: '使用日期',
    type: '类型',
    realname: '真实姓名',
    failMsg: '失败原因',
    cancelMsg: '取消原因',
    teamTurnover: '成交量',
    teamOrders: '交割笔数',
    lastTradingTime: '最后交易时间',
    invitationCodeRate: '邀请码利率',
    startTime: '开始时间',
    endTime: '结束时间',
    orderCredits: '接单值',
    securityDeposit: '保证金',
    minimumSecurityDeposit: '最低保证金',
    expiring: '即将过期',
    channel: '渠道',
    request: '申请',
  },
  title: {
    login: '欢迎登录',
    register: '注册',
    historyData: '历史数据',
    pay: '支付',
    rechargeDetail: '充值订单详情',
    success: '成功提示',
    buyCoinBankcard: '新增买币银行卡',
    withdrawalBankcard: '新增提现银行卡',
    detail: '详情',
    workbench: '数据统计',
    accountDetail: '账户明细',
    expirationTime: '过期时间:[[1]]分钟',
    priceFilter: '金额筛选',
    market: '市场',
    userAddress: '地址簿',
    order: '我的订单',
  },
  tabTitle: {
    buyCoins: '快捷买币',
    rechargeProcessing: '待付款',
    withdrawProcessing: '提现中',
    success: '成功',
    failed: '失败',
    haveInHand: '交割单',
    expired: '已过期',
    receiptFragment: '市场',
    contact: '我的',
    cCardPurchase: '购买记录',
    cCardPurchaseUsing: '生效中',
    cCardPurchaseIsUsed: '使用记录',
    withdrawal: '提现',
    recharge: '充值',
    processing: '交易中',
  },
  button: {
    login: '登录',
    logout: '登出',
    register: '注册',
    goToLogin: '前往登录',
    goToRegister: '前往注册',
    smsBtn: '发送验证码',
    smsResend: '重新发送',
    smsColdDown: '重新获取',
    second: ' 秒',
    pwdLogin: '密码登录',
    otpLogin: '短信登录',
    forgetPwd: '忘记密码',
    finish: '关闭',
    close: '关闭',
    open: '开启',
    turnOffAuto: '关闭自动接单',
    turnOnAuto: '开启自动接单',
    submit: '确认',
    confirm: '确认',
    nextBtn: '下一步',
    continueWithdraw: '继续提现',
    back: '返回',
    orderReceiving: '接单',
    completed: '完成',
    done: '完成',
    fail: '失败',
    share: '分享',
    purchase: '购买',
    purchased: '已购买',
    notReachRank: '等级不足',
    soldOut: '售罄',
    authorization: '授权',
    refund: '退还',
    uploadCertificate: '上传凭证',
  },
  alert: {
    default: '温馨提示',
    warnning: '提示',
    rechargeSuccessful: '确认充值成功?',
    hasReceived: '确认[[1]]收款[[2]][[3]]',
    notReceived: '确认未收到该款项',
    deductContribution: '扣除贡献值',
    cancel: '是否取消?',
    close: '是否关闭?',
    open: '是否开启?',
    purchase: '确认购买?',
    deleteBankCard: '是否删除此银行卡?',
    addRechargeBankCard: '请添加充值银行卡再做此操作',
    updateVersion: '更新到最新版本?',
    logout: '您确定要退出当前账号?',
    betOrderCancel: '确认撤回投注?',
    betOrderBatchCancel: '确认撤回所有投注?',
    deleteAuth: '确认取消授权?',
    addSecurityDeposit: '确认增加保证金?',
    refundSecurityDeposit: '确认退还保证金?',
    buyPayment: '确认购买[[1]][[2]]',
  },
  notify: {
    operateSuccess: '处理成功',
    copySuccess: '复制成功',
    sentSuccess: '发送成功',
    error: {
      accountFormat: '银行账户格式不正确',
    },
    orderTimeout1: '订单将在',
    orderTimeout2: '后失效，请及时付款',
    timeOut: '已超时',
    noSubordinates: '他还没有下级',
    insufficientAvailableBalance: '可用余额不足',
    loginAgain: '登录已失效,请重新登录!',
    matchPassword: '两次输入的新密码不一致',
    getAuth: '获取以下权限',
  },
  status: {
    opened: '已开启',
    unopened: '未开启',
    reviewFailed: '审核失败',
    inReview: '审核中',
    withdrawSuccess: '提现成功',
    returned: '已返还',
    notReturned: '未返还',
  },
  home: {
    getRebate: '获取返佣',
  },
  tier: {
    currentTier: '当前等级',
    memberSinceTime: '获得于 [[1]]',
    levelBtn: '等级级别',
    benefitBtn: '等级权益',
    congratulations: '恭喜你达成当前等级',
  },
}

export default lang;